import { useNavigate } from "react-router-dom";
import logo from "../../assets/img/logo.png";

const Navigation = () => {
  const navigate = useNavigate();

  const goTo = (path: string) => {
    navigate(path);
  };
  return (
    <header className="app-header">
      <img
        onClick={() => {
          goTo("/");
        }}
        src={logo}
        className="logo"
        alt="logo"
      />
      <div className="links">
        <p
          className="nav-link"
          onClick={() => {
            goTo("/work");
          }}
        >
          Work
        </p>
        <p
          className="nav-link"
          onClick={() => {
            goTo("/contact");
          }}
        >
          Contact
        </p>
      </div>
    </header>
  );
};

export default Navigation;

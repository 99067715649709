import { getDownloadURL, ref } from "firebase/storage";
import { storage } from "../../App";
import ImageControl, { ImageControlConfig } from "../controls/Image";
import { useEffect, useState } from "react";
import Navigation from "../controls/Navigation";

const Projects = () => {
  const [projects, setProjects] = useState<any>([]);

  useEffect(() => {}, []);

  return (
    <div className="projects-page">
      <Navigation />
      {/* {ProjectData.map((project: any) => (
        <a href={`/projects/${project?.link}`} key={project.id}>
          <h3>{project?.name}</h3>
        </a>
      ))} */}
    </div>
  );
};

export default Projects;

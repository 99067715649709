export interface ImageBoxProps {
  src: any;
  alt: string;
  [key: string]: any;
}

const ImageBox: React.FC<ImageBoxProps> = ({ src, alt, ...rest }) => {
  return (
    src && (
      <div className="image-box">
        <img src={src} alt={alt} {...rest} />
      </div>
    )
  );
};

export default ImageBox;

import { create } from "zustand";

export interface DataState {
  projects: any[];
  work: any[];
  setProjects: (projects: any[]) => void;
  setWork: (work: any[]) => void;
}

export const DataService = create<DataState>((set) => ({
  projects: [],
  work: [],
  setProjects: (projects) => set({ projects }),
  setWork: (work) => set({ work }),
}));

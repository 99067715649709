import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faReact,
  faAngular,
  faCss3,
  faHtml5,
  faNode,
  faGoogle,
  faFigma,
  faMicrosoft,
  faWordpress,
  faSquarespace,
} from "@fortawesome/free-brands-svg-icons";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

const WorkItem = ({ workItem }: any) => {
  const navigate = useNavigate();
  return (
    <div className="work-item section">
      <div className="section-heading">
        <h4>
          {workItem?.name}{" "}
          <span className="light-text">({workItem?.workDate})</span>{" "}
          {workItem?.siteLink && (
            <a
              className="work-link"
              href={workItem?.siteLink}
              target="_blank"
              title={workItem?.siteLink}
              rel="noreferrer"
            >
              <FontAwesomeIcon className="work-link-i" icon={faLink} />
            </a>
          )}
        </h4>
        {workItem?.title && (
          <h4 className="work-title light-text">{workItem?.title}</h4>
        )}
      </div>
      <div className="section-content">
        <p>{workItem?.brief}</p>
        <div className="used-tech">
          {workItem?.workTechnologies?.length &&
            workItem?.workTechnologies?.map((icon: string, i: any) => {
              return {
                react: <FontAwesomeIcon key={i} icon={faReact} title="React" />,
                angular: (
                  <FontAwesomeIcon
                    key={i}
                    icon={faAngular}
                    title="Angular16, AngularJS"
                  />
                ),
                css: (
                  <FontAwesomeIcon key={i} icon={faCss3} title="CSS3, SCSS" />
                ),
                html: <FontAwesomeIcon key={i} icon={faHtml5} title="HTML" />,
                node: <FontAwesomeIcon key={i} icon={faNode} title="Node" />,
                google: (
                  <FontAwesomeIcon
                    key={i}
                    icon={faGoogle}
                    title="Google, Firebase"
                  />
                ),
                figma: (
                  <FontAwesomeIcon key={i} icon={faFigma} title="Figma, XD" />
                ),
                microsoft: (
                  <FontAwesomeIcon
                    key={i}
                    icon={faMicrosoft}
                    title="Microsoft, Azure, DevOps"
                  />
                ),
                wordpress: (
                  <FontAwesomeIcon
                    key={i}
                    icon={faWordpress}
                    title="Wordpress, PHP"
                  />
                ),
                squarespace: (
                  <FontAwesomeIcon
                    key={i}
                    icon={faSquarespace}
                    title="Squarespace"
                  />
                ),
              }[icon];
            })}
        </div>
        {workItem?.linkName && (
          <div className="more-info">
            <button
              onClick={() => {
                navigate(`/work/${workItem?.linkName}`);
              }}
            >
              More Details
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default WorkItem;

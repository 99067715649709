import logo from "../../assets/img/logo.png";
const Loader = (props: any) => {
  return (
    <div className="loader">
      <img width="50" src={logo} alt="loading..."></img>
      {props.children}
    </div>
  );
};

export default Loader;

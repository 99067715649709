import { useParams } from "react-router-dom";
import logo from "../../assets/img/logo.png";
import { getDownloadURL, ref, listAll } from "firebase/storage";
import {
  getDoc,
  doc,
  collection,
  query,
  getDocs,
  where,
  limit,
  QuerySnapshot,
} from "firebase/firestore";
import { storage, firestore } from "../../App";
import ImageControl, { ImageControlConfig } from "../controls/Image";
import { useEffect, useState } from "react";
import ImageBox from "../controls/ImageBox";
import Navigation from "../controls/Navigation";

export interface ProjectConfig {}

const Project: React.FC<any> = () => {
  const { projectId } = useParams();
  const [projectData, setProjectData] = useState<any>({});

  const [projectImages, setProjectImages] = useState<any>([]);

  const images = ref(storage, `projects/${projectId}`);

  // When the projectID changes, fetch the project data
  useEffect(() => {
    const collectionRef = collection(firestore, "projects");
    const q = query(
      collectionRef,
      where("linkName", "==", projectId),
      limit(1)
    );
    getDocs(q).then((res: QuerySnapshot) => {
      setProjectData(res?.docs[0]?.data());
    });
  }, [projectId]);

  // When the projectData changes, fetch the project images
  useEffect(() => {
    if (projectData?.featuredImage) {
      getFiles([projectData?.featuredImage]);
    }
  }, [projectData]);

  const getFiles = (files: any) => {
    // Create an array to store promises for fetching download URLs
    const promises: any = [];

    // Loop through each filename and create a reference to the file
    files.forEach((filename: any) => {
      const fileRef = ref(storage, `images/${filename}`);
      promises.push(getDownloadURL(fileRef));
    });

    // Execute all promises concurrently
    Promise.all(promises)
      .then((urls) => {
        console.log("File URLs:", urls);
        const imgs = urls.map((url) => {
          return {
            src: url,
            alt: projectId,
          };
        });

        setProjectImages(imgs);
      })
      .catch((error) => {
        console.error("Error getting download URLs:", error);
      });
  };

  return (
    <div className="app">
      <Navigation />
      <h1>{projectData?.name}</h1>
      <ImageBox
        src={projectImages[0]?.src}
        alt={projectData?.name || ""}
        height={400}
      />
    </div>
  );
};
export default Project;

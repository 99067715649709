import { useState } from "react";
import logo from "../../assets/img/logo.png";
import WorkItems from "../../assets/data/work.data";
import WorkItem from "../controls/WorkItem";
import file from "../../assets/data/Tuttle_Resume.pdf";
import TextControl from "../controls/Text";
import { DataService } from "../../services/DataService";
import Navigation from "../controls/Navigation";

const Home = () => {
  // const [workItems] = useState(WorkItems);

  const workItems = DataService((state) => state.work);

  const openInNewTab = (url: string) => {
    //   logEvent(analytics, `button_click`, {buttonURL: url} );
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  return (
    <div className="app">
      <Navigation />
      <div id="about" className="section">
        <div className="section-heading">
          <TextControl type="h2" text="About" />
        </div>
        <div className="section-content">
          <TextControl
            type="p"
            text="Hello, I'm Nick, a seasoned full-stack developer and designer. I
            specialize in Angular and React, API integration, UX/UI design, and
            robust stack architecture with a strong track record in the web
            application space. I have also founded HighPine Media, building web
            services enabled for small business. Beyond tech, I'm an outdoor
            enthusiast, fly fisher, DIYer, and music enthusiast, constantly
            bringing creativity and innovation to all aspects of my life."
          />
        </div>
      </div>
      <div className="resume-block">
        <button
          onClick={() => {
            openInNewTab(file);
          }}
        >
          Get Resume
        </button>
      </div>
      <div id="work" className="section">
        <div className="section-heading">
          <TextControl type="h2" text="Experience" />
        </div>
        <div className="section-content">
          <div className="work-exp">
            {workItems.map((wi) => (
              <WorkItem workItem={wi} key={wi?.name}></WorkItem>
            ))}
          </div>
        </div>
      </div>
      <div id="contact" className="section">
        <div className="section-heading">
          <TextControl type="h2" text="Contact" />
        </div>
        <div className="section-content">
          <div className="contact-block">
            <button
              onClick={() => {
                openInNewTab(file);
              }}
            >
              Resume
            </button>
            <button
              onClick={() => {
                openInNewTab(
                  "mailto:nick@nstuttle.com?subject=Re:Nstuttle.com"
                );
              }}
            >
              Email
            </button>
            <button
              onClick={() => {
                openInNewTab("https://www.linkedin.com/in/nstuttle/");
              }}
            >
              LinkedIn
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;

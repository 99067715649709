import logo from "../../assets/img/logo.png";
import { DataService } from "../../services/DataService";
import Navigation from "../controls/Navigation";
import TextControl from "../controls/Text";
import WorkItem from "../controls/WorkItem";

const Work = () => {
  const workItems = DataService((state) => state.work);
  return (
    <div className="app">
      <Navigation />
      <div id="work" className="section">
        <div className="section-heading">
          <TextControl type="h2" text="Experience" />
        </div>
        <div className="section-content">
          <div className="work-exp">
            {workItems.map((wi) => (
              <WorkItem workItem={wi} key={wi?.name}></WorkItem>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Work;

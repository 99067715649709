import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Projects from "./components/views/Projects";
import Home from "./components/views/Home";
import Project from "./components/views/Project";
import WorkDetail from "./components/views/WorkDetail";
import Work from "./components/views/Work";

const AppRouter = () => {
  return (
    <Router>
      <Routes>
        <Route path="/projects/:projectId" element={<Project />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/work/:workId" element={<WorkDetail />} />
        <Route path="/work" element={<Work />} />
        <Route path="/" element={<Home />} />
        <Route path="*" element={<Navigate replace to="/" />} />
      </Routes>
    </Router>
  );
};

export default AppRouter;

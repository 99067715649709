import { useEffect, useState } from "react";
import logo from "../../assets/img/logo.png";
import { useNavigate, useParams } from "react-router-dom";
import {
  getDoc,
  doc,
  collection,
  query,
  where,
  limit,
  getDocs,
  QuerySnapshot,
} from "firebase/firestore";
import { storage, firestore } from "../../App";
import TextControl from "../controls/Text";
import { DataService } from "../../services/DataService";
import Navigation from "../controls/Navigation";

const WorkDetail = () => {
  const navigate = useNavigate();
  const { workId } = useParams();
  const [workItem, setWorkItem] = useState<any>({});
  const workItems = DataService((state) => state.work);

  const openInNewTab = (url: string) => {
    //   logEvent(analytics, `button_click`, {buttonURL: url} );
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  useEffect(() => {
    if (workItems.length) {
      const item = workItems.find((w: any) => w?.linkName === workId);
      if (item?.projects?.length) {
        item.projects.sort((a: any, b: any) => a.order - b.order);
      }
      setWorkItem(item);
    }
  }, [workId]);

  return (
    <div className="app">
      <Navigation />
      <div className="work-detail">
        <div className="section">
          <div className="section-heading">
            <TextControl type="h2" text={workItem?.name} />
            <TextControl type="p" text={workItem?.title} />
          </div>
          <div className="section-content">
            <p>{workItem?.brief}</p>
            {workItem?.projects?.length &&
              workItem?.projects?.map((project: any) => (
                <div
                  className="section work-project-container"
                  key={project.name}
                >
                  <h3 className="project-name">{project.name}</h3>
                  <p className="project-history">{project?.history}</p>
                  <div className="project-detail">
                    <div className="project-features">
                      <ul>
                        {project?.keyFeatures?.map((kf: any) => (
                          <li className="feature" key={kf}>
                            {kf}
                          </li>
                        ))}
                      </ul>
                    </div>
                    {project?.featuredImage && (
                      <div className="project-image">
                        <img
                          width="600"
                          src={"data:png;base64," + project?.featuredImage}
                        />
                      </div>
                    )}
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkDetail;

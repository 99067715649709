import "./App.scss";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import { getStorage } from "firebase/storage";
import AppRouter from "./Router";
import { DataService } from "./services/DataService";
import { useEffect, useState } from "react";

import {
  getFirestore,
  collection,
  query,
  where,
  limit,
  getDocs,
  QuerySnapshot,
} from "firebase/firestore";
import { get } from "http";
import Loader from "./components/controls/Loader";

const firebaseConfig = {
  apiKey: "AIzaSyAB8XugnrINPJ-NeFpBv2UtYEH8hzI4ig4",
  authDomain: "nstuttle-4e224.firebaseapp.com",
  databaseURL: "https://nstuttle-4e224.firebaseio.com",
  projectId: "nstuttle-4e224",
  storageBucket: "nstuttle-4e224.appspot.com",
  messagingSenderId: "950027065950",
  appId: "1:950027065950:web:2134854495fe2deed0f2cf",
  measurementId: "G-69Y6CMSTLS",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const storage = getStorage(app);
export const firestore = getFirestore(app);

const App = () => {
  const [loading, setLoading] = useState(true);
  const setProjects = DataService((state) => state.setProjects);
  const setWork = DataService((state) => state.setWork);

  useEffect(() => {
    const workCollection = collection(firestore, "work");
    const workq = query(workCollection);
    const projCollection = collection(firestore, "projects");
    const projq = query(projCollection);
    const dataPromises = [getDocs(workq), getDocs(projq)];

    Promise.all(dataPromises)
      .then((res) => {
        const workItems = res[0].docs.map((doc) => doc.data());
        workItems.sort((a, b) => {
          if (!!a?.order && !!b?.order) {
            if (a?.order > b?.order) return 1;
            if (a?.order < b?.order) return -1;
          }
          return -1;
        });
        const projectItems = res[1].docs.map((doc) => doc.data());
        if (workItems) {
          setWork(workItems);
        }
        if (projectItems) {
          setProjects(projectItems);
        }
        setLoading(false);
      })
      .catch((err) => {});
  }, []);

  return !!loading ? (
    <Loader>
      <h3>LOADING</h3>
    </Loader>
  ) : (
    <AppRouter />
  );
};

export default App;
